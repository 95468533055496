/** @format */

import { Menu, Transition } from "@headlessui/react";
import { Bars3Icon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { usePlatformDetect } from "@roadflex/react-hooks";
import classNames from "classnames";
import getConfig from "next/config";
import { Fragment, useState } from "react";
import Navigation from "../navigation/navigation";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;

type HeaderUIProps = {
  handleLogout: any;
  firstName: any;
  lastName: any;
};

const UserMenu = ({ handleLogout, firstName, lastName }: HeaderUIProps) => (
  <Menu as="div" className="relative ml-3 rounded-md">
    <div>
      <Menu.Button className="flex items-center max-w-xs text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
        <UserCircleIcon className="w-8 h-8 text-gray-700" aria-hidden="true" />
        <span className="hidden ml-2 text-sm font-medium text-gray-700 lg:block">
          <span className="sr-only">Open user menu for</span>
          {firstName} {lastName}
        </span>
        <ChevronDownIcon
          className="flex-shrink-0 hidden w-5 h-5 ml-2 text-gray-400 lg:block"
          aria-hidden="true"
        />
      </Menu.Button>
    </div>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <Menu.Item>
          {({ active }) => (
            <div
              // href="#"
              className={classNames(
                active ? "bg-gray-100" : "",
                "block px-4 py-2 text-sm text-gray-400 cursor-not-allowed",
              )}
            >
              Your Profile
            </div>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <div
              // href="#"
              className={classNames(
                active ? "bg-gray-100" : "",
                "block px-4 py-2 text-sm text-gray-700 cursor-pointer",
              )}
              onClick={handleLogout}
            >
              Logout
            </div>
          )}
        </Menu.Item>
      </Menu.Items>
    </Transition>
  </Menu>
);

export default function HeaderUI({
  handleLogout,
  firstName,
  lastName,
}: HeaderUIProps) {
  const [open, setOpen] = useState<boolean>(false);
  const { isMobile } = usePlatformDetect();
  const toggle = () => {
    setOpen((o) => !o);
  };
  return (
    <>
      {!isMobile() && (
        <div className="flex justify-between flex-1 px-8">
          <div className="flex flex-1"></div>
          <div className="flex items-center ml-4 md:ml-6">
            <UserMenu
              handleLogout={handleLogout}
              firstName={firstName}
              lastName={lastName}
            />
          </div>
        </div>
      )}
      {isMobile() && (
        <div className="sticky top-0 z-10 flex flex-shrink-0 w-full h-16 bg-gray-600">
          <div className="flex justify-between w-full md:flex-row-reverse">
            <img
              className="w-[120px] md:hidden block ml-4"
              src={`${NEXT_PUBLIC_CDN_URL}/svg/roadflex-full-logo.svg`}
              alt="RoadFlex"
            />
            <div className="flex items-center">
              <UserMenu
                handleLogout={handleLogout}
                firstName={firstName}
                lastName={lastName}
              />
              <button
                onClick={toggle}
                type="button"
                className="px-4 ml-4 text-white border-l border-gray-200 focus:outline-none focus:ring-0 focus:ring-inset"
              >
                <Bars3Icon className="w-6 h-6" aria-hidden="true" />
              </button>
            </div>
          </div>
          <Navigation open={open} toggle={toggle} />
        </div>
      )}
    </>
  );
}
