import { useMutation } from "@apollo/client";
import { genericPasswordSchema } from "@roadflex/constants";
import { LOGOUT_ALL_USERS, UPDATE_ADMIN_PASSWORD } from "@roadflex/graphql";
import { useFormik } from "formik";

type useSetPasswordProps = {
  onSubmit: (val: any, error: any) => void;
  initialValues: {
    currentPassword: string;
    password: string;
    retypePassword: string;
  };
};

type useLogOutAllUsersProps = {
  onSubmit: (val: any, error: any) => void;
  initialValues: {
    userType: string;
  };
};

export const useUpdateAdminPassword = ({
  onSubmit,
  initialValues,
}: useSetPasswordProps) => {
  const [updateAdminPasswordFn] = useMutation<{
    updateAdminPassword: {
      code: string;
      message: string;
    };
  }>(UPDATE_ADMIN_PASSWORD);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    validationSchema: genericPasswordSchema,
    onSubmit: async (value) => {
      try {
        const res = await updateAdminPasswordFn({
          variables: { data: value },
        });
        if (res?.data?.updateAdminPassword) {
          onSubmit(res?.data?.updateAdminPassword, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};

export const useLogOutAllUsers = ({
  onSubmit,
  initialValues,
}: useLogOutAllUsersProps) => {
  const [logOutAllUsersFn] = useMutation<{
    logoutAllUsers: {
      code: string;
      message: string;
    };
  }>(LOGOUT_ALL_USERS);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    setFieldValue,
    ...rest
  } = useFormik({
    initialValues,
    onSubmit: async (value) => {
      try {
        const res = await logOutAllUsersFn({
          variables: { data: value },
        });
        if (res?.data?.logoutAllUsers) {
          onSubmit(res?.data?.logoutAllUsers, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    ...rest,
  };
};
