import { useMutation, useQuery } from "@apollo/client";
import { ENUM_SENDGRID_TEMPLATE } from "@prisma/client";
import { adminAssignMailType, adminSmsEmailTest } from "@roadflex/constants";
import {
  CREATE_UPDATE_ADMIN_EMAIL_TEMPLATE,
  CREATE_UPDATE_MAIL_TYPE,
  DELETE_ADMIN_EMAIL_TEMPLATE,
  READ_ADMIN_EMAIL_TEMPLATE_LIST,
  TEST_EMAIL_OR_SMS_TEMPLATE,
} from "@roadflex/graphql";
import {
  EmailTemplateDetails,
  MailTypeDetails,
  SendGridTemplateDetails,
} from "@roadflex/types";
import { useFormik } from "formik";

type useCreateUpdateAdminEmailTemplateProps = {
  initialValues: {
    templateName: string;
    emailId: string;
    variables: Record<string, string>;
    sendgridTemplateType: ENUM_SENDGRID_TEMPLATE;
  };
  onSubmit: (val: any, err: any) => void;
};

type useDeleteAdminEmailTemplateProps = {
  initialValues: {
    idList: [string];
  };
  onSubmit: (val: any, err: any) => void;
};

type useCreateUpdateMailTypeProps = {
  initialValues: {
    mailType: string;
    templateName: string;
  };
  onSubmit: (val: any, err: any) => void;
};

type useTestEmailOrSmsProps = {
  initialValues: {
    mode: string;
    phoneNumber: string;
    smsTemplate: string;
    emailTemplateId: string;
    emailList: [string];
    variables: Record<string, string>;
    emailId: string;
  };
  onSubmit: (val: any, err: any) => void;
};

export function useReadAdminEmailTemplates() {
  const { data, refetch } = useQuery<{
    readAdminEmailTemplateList: {
      code: string;
      message: string;
      sendGridTemplates: SendGridTemplateDetails[];
      emailTemplates: EmailTemplateDetails[];
      mailTypes: MailTypeDetails[];
    };
  }>(READ_ADMIN_EMAIL_TEMPLATE_LIST, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const adminEmailTemplateList: EmailTemplateDetails[] =
    data?.readAdminEmailTemplateList?.emailTemplates?.map((value: any) => {
      return {
        id: value.id,
        templateName: value.templateName,
        email: value.emailId,
        sendgridTemplateType: value.sendgridTemplateType,
        variables: value.variables,
      };
    }) || [];

  const sendGridTemplateList: SendGridTemplateDetails[] =
    data?.readAdminEmailTemplateList?.sendGridTemplates?.map((value: any) => {
      return {
        id: value.id,
        sendgridTemplateType: value.sendgridTemplateType,
        variableNames: value.variableNames,
      };
    }) || [];
  return {
    adminEmailTemplateList,
    sendGridTemplateList,
    mailTypes: data?.readAdminEmailTemplateList?.mailTypes,
    refetchTemplates: refetch,
  };
}
export function useCreateUpdateAdminEmailTemplate({
  onSubmit,
  initialValues,
}: useCreateUpdateAdminEmailTemplateProps) {
  const [createUpdateAdminEmailTemplateFn] = useMutation<{
    createUpdateAdminEmailTemplate: {
      code: string;
      message: string;
    };
  }>(CREATE_UPDATE_ADMIN_EMAIL_TEMPLATE);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (value) => {
      try {
        const res = await createUpdateAdminEmailTemplateFn({
          variables: { data: value },
        });
        if (res?.data?.createUpdateAdminEmailTemplate) {
          onSubmit(res?.data?.createUpdateAdminEmailTemplate, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
}
export function useDeleteAdminEmailTemplate({
  onSubmit,
  initialValues,
}: useDeleteAdminEmailTemplateProps) {
  const [deleteAdminEmailTemplateFn] = useMutation<{
    deleteAdminEmailTemplate: {
      code: string;
      message: string;
    };
  }>(DELETE_ADMIN_EMAIL_TEMPLATE);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (value) => {
      try {
        const res = await deleteAdminEmailTemplateFn({
          variables: { data: value },
        });
        if (res?.data?.deleteAdminEmailTemplate) {
          onSubmit(res?.data?.deleteAdminEmailTemplate, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
}
export function useCreateUpdateMailType({
  onSubmit,
  initialValues,
}: useCreateUpdateMailTypeProps) {
  const [createUpdateMailTypeFn] = useMutation<{
    createUpdateMailType: {
      code: string;
      message: string;
    };
  }>(CREATE_UPDATE_MAIL_TYPE);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: adminAssignMailType,
    onSubmit: async (value) => {
      try {
        const res = await createUpdateMailTypeFn({
          variables: { data: value },
        });
        if (res?.data?.createUpdateMailType) {
          onSubmit(res?.data?.createUpdateMailType, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
}
export function useTestEmailOrSms({
  onSubmit,
  initialValues,
}: useTestEmailOrSmsProps) {
  const [testEmailOrSmsTemplateFn] = useMutation<{
    testEmailOrSmsTemplate: {
      code: string;
      message: string;
    };
  }>(TEST_EMAIL_OR_SMS_TEMPLATE);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: adminSmsEmailTest,
    onSubmit: async (value) => {
      try {
        const res = await testEmailOrSmsTemplateFn({
          variables: { data: value },
        });
        if (res?.data?.testEmailOrSmsTemplate) {
          onSubmit(res?.data?.testEmailOrSmsTemplate, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
}
