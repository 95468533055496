/** @format */
import { useMutation } from "@apollo/client";
import { adminPrefillSchema } from "@roadflex/constants";
import {
  ADMIN_PREFILL_ONBOARD,
  ADMIN_PREFILL_ONBOARD_RESEND_EMAIL,
} from "@roadflex/graphql";
import { AdminPrefillValues } from "@roadflex/types";
import { useFormik } from "formik";

type useAdminPrefillSignupProps = {
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
  initialValues: AdminPrefillValues;
};

export const useAdminPrefillSignup = ({
  onSubmit,
  initialValues,
}: useAdminPrefillSignupProps) => {
  const [adminPrefillOnboard] = useMutation<{
    adminOnboard: {
      code: string;
      message: string;
    };
  }>(ADMIN_PREFILL_ONBOARD);
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    validationSchema: adminPrefillSchema,
    onSubmit: async (value) => {
      try {
        const payloadData = {
          ...value,
          phoneNumber: value.phoneNumber.trim().match(/\d+/g)?.join(""),
        };
        // console.log("data => ", payloadData);
        const res = await adminPrefillOnboard({
          variables: { data: payloadData },
        });
        if (res?.data?.adminOnboard) {
          onSubmit(res?.data?.adminOnboard, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};

type useAdminPrefillSignupResendEmailProps = {
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
  initialValues: {
    email: string;
  };
};

export const useAdminPrefillSignupResendEmail = ({
  onSubmit,
  initialValues,
}: useAdminPrefillSignupResendEmailProps) => {
  const [adminPrefillOnboardResendEmailFn] = useMutation<{
    adminOnboardResendEmail: {
      code: string;
      message: string;
    };
  }>(ADMIN_PREFILL_ONBOARD_RESEND_EMAIL);
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    onSubmit: async (value) => {
      try {
        const res = await adminPrefillOnboardResendEmailFn({
          variables: { data: value },
        });
        if (res?.data?.adminOnboardResendEmail) {
          onSubmit(res?.data?.adminOnboardResendEmail, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};
