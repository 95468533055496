/** @format */

import { AdminType, UserType } from "@roadflex/types";
import { createContext } from "react";

export const CurrentUserContext = createContext<UserType | undefined>(
  undefined,
);

export const CurrentAdminContext = createContext<AdminType | undefined>(
  undefined,
);
