import { useQuery } from "@apollo/client";
import { GET_DISPLAY_UNDERWRITING_DATA_STATUS } from "@roadflex/graphql";

interface useGetUnderwritingDataStatusProps {
  businessId: string;
  userLeadId: string;
}

export const useGetUnderwritingDataStatus = ({
  businessId,
  userLeadId,
}: useGetUnderwritingDataStatusProps) => {
  const {
    data: underwritingDataStatus,
    refetch: underwritingDataStatusRefetch,
    loading: underwritingDataStatusLoading,
    ...rest
  } = useQuery(GET_DISPLAY_UNDERWRITING_DATA_STATUS, {
    variables: {
      data: {
        businessId,
        userLeadId,
      },
    },
    fetchPolicy: "no-cache",
  });

  return {
    underwritingDataStatus,
    underwritingDataStatusRefetch,
    underwritingDataStatusLoading,
    ...rest,
  };
};
