/** @format */

import classNames from "classnames";
import getConfig from "next/config";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment } from "react";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;

export interface NavigationUIProps {
  navigation: any;
  page: any;
  setPage: any;
}

export default function NavigationUI({
  navigation,
  page,
  setPage,
}: NavigationUIProps) {
  const router = useRouter();

  return (
    <>
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div
        className={`h-full hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 bg-gradient-to-br from-brown-850 to-brown-750`}
      >
        <div className="flex flex-col flex-grow pt-5 overflow-y-auto">
          <div className="relative h-16 m-2">
            <Image
              className=""
              src={`${NEXT_PUBLIC_CDN_URL}/svg/roadflex-full-logo.svg`}
              alt="Roadflex logo"
              layout="fill"
            />
          </div>
          <nav
            className="flex flex-col flex-1 mt-5 overflow-y-auto divide-y divide-white no-scrollbar"
            aria-label="Sidebar"
          >
            <div className="space-y-1">
              {navigation.map((navBlock: any, index: number) => (
                <Fragment key={index}>
                  {navBlock.map((item: any) => (
                    <Link href={item.href} key={item.name} prefetch={false}>
                      <a
                        key={item.name}
                        onClick={(e) => setPage(item.current)}
                        className={classNames(
                          item.href === router?.asPath
                            ? "font-semibold"
                            : "hover:animate-pulse",
                          `text-white group flex items-center px-3 py-2 text-xs leading-6`,
                        )}
                        aria-current={
                          page === item.current ? "page" : undefined
                        }
                      >
                        <item.icon
                          className="flex-shrink-0 w-6 h-6 mr-4 text-white"
                          aria-hidden="true"
                        />
                        {item.name}
                      </a>
                    </Link>
                  ))}
                  {navBlock.length > 0 && (
                    <div className="w-full h-[1px] bg-orange-200 last:hidden"></div>
                  )}
                </Fragment>
              ))}
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}
