import { useQuery } from "@apollo/client";
import { READ_AFFILIATES, READ_SUBSCRIBERS } from "@roadflex/graphql";
import { AffiliateType, SubscriberType } from "@roadflex/types";

export const useReadSubscribers = () => {
  const { data: subscriberList, loading: subscriberListLoading } = useQuery<{
    readSubscribers: {
      subscriberList: SubscriberType[];
    };
  }>(READ_SUBSCRIBERS, {
    fetchPolicy: "no-cache",
  });

  return {
    subscriberList,
    subscriberListLoading,
  };
};

export const useReadAffiliates = () => {
  const { data: affiliatesList, loading: affiliatesListLoading } = useQuery<{
    readAffiliates: {
      affiliatesList: AffiliateType[];
    };
  }>(READ_AFFILIATES, {
    fetchPolicy: "no-cache",
  });

  return {
    affiliatesList,
    affiliatesListLoading,
  };
};
