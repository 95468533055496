/* eslint-disable dot-notation */
/** @format */
import { useMutation, useQuery } from "@apollo/client";
import {
  ENUM_COMMENT_TYPE,
  ENUM_CREDIT_CYCLE_TYPE,
  ENUM_CUSTOMER_TIER,
  ENUM_CUSTOMER_TYPE,
  ENUM_PLATFORM_TYPE,
} from "@prisma/client";
import {
  CREATE_ADMIN_COMMENT,
  CREATE_USER_CREDIT_BY_ADMIN,
  READ_ADMIN_COMMENTS,
  READ_CURRENT_ADMIN,
  READ_USER_DETAILS,
  UPDATE_ACCOUNT_STATUS,
  UPDATE_ADMIN_COMMENT,
  UPDATE_CUSTOMER_SUPPORT_SALES_CUSTOMER_TIER,
} from "@roadflex/graphql";
import { AdminType, CommentsType } from "@roadflex/types";
import { useFormik } from "formik";

export type UseApprovalValuesType = {
  userId?: string;
  userLeadId?: string;
  customerType?: ENUM_CUSTOMER_TYPE;
  creditCycleType: ENUM_CREDIT_CYCLE_TYPE;
  creditLimit: number;
  metaData?: JSON;
  numberOfCards: number;
  status: string;
  platform?: ENUM_PLATFORM_TYPE;
};

type UseApprovalProps = {
  initialValues: UseApprovalValuesType;
  onSubmit: (val: any, err: any) => void;
};

type UseUpdateCustomerSupportSalesCustomerTierProps = {
  initialValues: {
    userEmail: string;
    customerTier: ENUM_CUSTOMER_TIER;
    customerSupportAgent: string;
    salesAgent: string;
  };
  onSubmit: (val: any, err: any) => void;
};

type UseAddCommentAdminProps = {
  initialValues: {
    userId: string;
    comments: string;
    type: ENUM_COMMENT_TYPE;
  };
  onSubmit: (val: any, err: any) => void;
};

type UseUpdateCommentAdminProps = {
  initialValues: {
    commentId: string;
    pin: boolean;
  };
  onSubmit: (val: any, err: any) => void;
};

type UseAddUserCreditByAdminProps = {
  initialValues: {
    userId: string;
    userLeadId: string;
    credit: number;
    details: string;
  };
  onSubmit: (val: any, err: any) => void;
};

export const useUpdateAccountStatus = ({
  onSubmit,
  initialValues,
}: UseApprovalProps) => {
  const [updateAccountStatusFn] = useMutation(UPDATE_ACCOUNT_STATUS);

  const { handleChange, handleSubmit, values, isSubmitting, ...rest } =
    useFormik({
      initialValues,
      onSubmit: async (value) => {
        const data = {
          ...value,
          creditLimit: value.creditLimit * 100,
          numberOfCards: value.numberOfCards,
          customerType: value?.customerType || undefined,
        };
        try {
          const res = await updateAccountStatusFn({
            variables: { data },
          });
          onSubmit(res, null);
        } catch (error) {
          onSubmit(null, error);
        }
      },
    });

  return {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};

export function useReadCurrentAdmin() {
  const { data, ...rest } = useQuery<{
    readCurrentAdmin: AdminType;
  }>(READ_CURRENT_ADMIN, {
    fetchPolicy: "no-cache",
  });

  return { data, ...rest };
}

export const useUpdateCustomerSupportSalesCustomerTier = ({
  onSubmit,
  initialValues,
}: UseUpdateCustomerSupportSalesCustomerTierProps) => {
  const [updateCustomerSupportSalesCustomerTierFn] = useMutation<{
    updateCustomerSupportSalesCustomerTier: {
      code: string;
      message: string;
    };
  }>(UPDATE_CUSTOMER_SUPPORT_SALES_CUSTOMER_TIER, {
    refetchQueries: [READ_USER_DETAILS],
  });

  const { setFieldValue, handleSubmit, values, ...rest } = useFormik({
    initialValues,
    onSubmit: async (input) => {
      const data = { ...input };
      try {
        const res = await updateCustomerSupportSalesCustomerTierFn({
          variables: { data },
        });
        if (res?.data?.updateCustomerSupportSalesCustomerTier) {
          onSubmit(res?.data?.updateCustomerSupportSalesCustomerTier, null);
        }
      } catch (error) {
        onSubmit(null, error);
      }
    },
  });

  return {
    setFieldValue,
    handleSubmit,
    values,
    ...rest,
  };
};

export const useCreateAdminComment = ({
  onSubmit,
  initialValues,
}: UseAddCommentAdminProps) => {
  const [CreateAdminCommentFn] = useMutation<{
    createAdminComment: {
      code: string;
      message: string;
    };
  }>(CREATE_ADMIN_COMMENT, {});

  const {
    setFieldValue: createCommentSetFieldValue,
    handleSubmit: createCommentHandleSubmit,
    ...rest
  } = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const data = { ...values };
      try {
        const res = await CreateAdminCommentFn({
          variables: { data },
        });
        if (res?.data?.createAdminComment) {
          onSubmit(res?.data?.createAdminComment, null);
        }
      } catch (error) {
        onSubmit(null, error);
      }
    },
  });

  return {
    createCommentSetFieldValue,
    createCommentHandleSubmit,
    ...rest,
  };
};

export const useUpdateAdminComment = ({
  onSubmit,
  initialValues,
}: UseUpdateCommentAdminProps) => {
  const [UpdateAdminCommmentFn] = useMutation<{
    updateAdminComment: {
      code: string;
      message: string;
    };
  }>(UPDATE_ADMIN_COMMENT, {});
  const {
    setFieldValue: updateCommentSetFieldValue,
    handleSubmit: updateCommentHandleSubmit,
    isSubmitting: updateCommentIsSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const data = { ...values };
      try {
        const res = await UpdateAdminCommmentFn({
          variables: { data },
        });
        if (res?.data?.updateAdminComment) {
          onSubmit(res?.data?.updateAdminComment, null);
        }
      } catch (error) {
        onSubmit(null, error);
      }
    },
  });

  return {
    updateCommentSetFieldValue,
    updateCommentHandleSubmit,
    updateCommentIsSubmitting,
    ...rest,
  };
};

export function useReadAdminComment({ email }: { email: string }) {
  const { data: commentsData, ...rest } = useQuery<{
    readAdminComments: {
      code: string;
      message: string;
      comments: CommentsType[];
    };
  }>(READ_ADMIN_COMMENTS, {
    fetchPolicy: "no-cache",
    variables: { data: { email: email } },
  });

  return { commentsData, ...rest };
}
export const useCreateCreditByAdmin = ({
  onSubmit,
  initialValues,
}: UseAddUserCreditByAdminProps) => {
  const [CreateUserCreditByAdminFn] = useMutation<{
    createUserCreditByAdmin: {
      code: string;
      message: string;
    };
  }>(CREATE_USER_CREDIT_BY_ADMIN);

  const {
    handleChange: createCreditByAdminHandleChange,
    handleSubmit: createCreditByAdminHandleSubmit,
    setFieldValue: createCreditByAdminSetFieldValue,
    values: createCreditByAdminValues,
    isSubmitting: createCreditByAdminIsSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const data = {
        ...values,
        credit: Math.trunc(Number(values?.credit) * 100),
      };
      try {
        const res = await CreateUserCreditByAdminFn({
          variables: { data },
        });
        if (res?.data?.createUserCreditByAdmin) {
          onSubmit(res?.data?.createUserCreditByAdmin, null);
        }
      } catch (error) {
        onSubmit(null, error);
      }
    },
  });

  return {
    createCreditByAdminHandleSubmit,
    createCreditByAdminValues,
    createCreditByAdminSetFieldValue,
    ...rest,
  };
};
