/** @format */

import { ApolloProvider } from "@apollo/client";
import { HeadSeo } from "@roadflex/react-components";
import { CurrentAdminContext } from "@roadflex/react-contexts";
import { useReadCurrentAdmin } from "@roadflex/react-hooks";
import NextProgress from "next-progress";
import type { AppProps } from "next/app";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "tailwindcss/tailwind.css";
import { useApollo } from "../apollo/client";
import Layout from "../components/layouts/adminLayout";
import "./styles.css";
import "./stylesPrimeReact.css";

function MyAppWithCurrentUser({ children }: { children: JSX.Element }) {
  const { data, loading, refetch } = useReadCurrentAdmin();
  const { readCurrentAdmin } = data || {};

  return (
    <CurrentAdminContext.Provider value={{ ...readCurrentAdmin }}>
      {children}
    </CurrentAdminContext.Provider>
  );
}

function CustomApp({ Component, pageProps }: AppProps) {
  const apolloClient = useApollo(pageProps.initialApolloState);

  return (
    <ApolloProvider client={apolloClient}>
      <HeadSeo />

      <NextProgress
        color="#ed4e34"
        height={5}
        delay={0}
        options={{ minimum: 0.3, trickleSpeed: 100 }}
      />

      <ToastContainer />
      <MyAppWithCurrentUser>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </MyAppWithCurrentUser>
    </ApolloProvider>
  );
}

export default CustomApp;
