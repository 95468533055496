/** @format */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAchPaymentFailed, usePlatformDetect } from "@roadflex/react-hooks";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useEffect, useRef } from "react";

export type AchManualPaymentFailedModalProps = {
  startDate: string;
  endDate: string;
  openAchManualPaymentFailedModal: boolean;
  setAchManualPaymentFailedModal: (
    openAchManualPaymentFailedModal: boolean,
  ) => void;
};

export const AchManualPaymentFailedModal = ({
  startDate,
  endDate,
  openAchManualPaymentFailedModal,
  setAchManualPaymentFailedModal,
}: AchManualPaymentFailedModalProps) => {
  const {
    loadAchPaymentFailed,
    achPaymentFailedData,
    achPaymentFailedLoading,
  } = useAchPaymentFailed({
    paymentHistoryStartDate: startDate,
    paymentHistoryEndDate: endDate,
    paymentStatus: "FAILED",
    isAutomatedPayment: false,
  });
  const { isMobile } = usePlatformDetect();

  const failureReason = (rowData: any) => {
    return `Code: ${rowData?.failureReason?.code} - Type: ${rowData?.failureReason?.type} - Description: ${rowData?.failureReason?.description}`;
  };

  const dt = useRef(null);

  const exportCSV = (selectionOnly: boolean) => {
    (dt.current as any).exportCSV({ selectionOnly });
  };

  const renderFooter = () => {
    return (
      <div>
        <div className="inline-flex items-center text-sm font-medium leading-4">
          <Button
            type="button"
            icon="pi pi-download"
            label="Download CSV"
            className="p-button-outlined p-button-secondary p-button-sm"
            onClick={() => exportCSV(false)}
            style={{ fontFamily: "Inter" }}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (openAchManualPaymentFailedModal) {
      loadAchPaymentFailed();
    }
  }, [openAchManualPaymentFailedModal]);

  const achPaymentData =
    achPaymentFailedData?.achPaymentStatus?.achPaymentList || [];

  return (
    <Dialog
      header={
        <div className="text-base lg:text-lg">Manual payment failed model</div>
      }
      visible={openAchManualPaymentFailedModal}
      style={{
        width: isMobile() ? "90vw" : "70vw",
        fontFamily: "Inter",
      }}
      footer={renderFooter()}
      onHide={() => setAchManualPaymentFailedModal(false)}
    >
      <div className="border-b border-gray-200 shadow sm:rounded-md">
        <DataTable
          size="small"
          style={{ fontFamily: "Inter" }}
          loading={achPaymentFailedLoading}
          value={achPaymentData}
          responsiveLayout="scroll"
          ref={dt}
          exportFilename={`ManualFailedPayment_${new Date().toLocaleDateString(
            "en-US",
          )}`}
        >
          <Column
            bodyClassName="text-sm text-gray-500"
            field="payIntentId"
            header="Pay Intend Id"
          ></Column>
          <Column
            bodyClassName="text-sm text-gray-500"
            field="chargeId"
            header="Charge Id"
          ></Column>
          <Column
            bodyClassName="text-sm text-gray-500"
            field="cardHolder.user.email"
            header="Email"
          ></Column>
          <Column
            bodyClassName="text-sm text-gray-500"
            field="failureReason.code"
            header="Failure Reason"
            body={failureReason}
          ></Column>
        </DataTable>
      </div>
    </Dialog>
  );
};
