import { useMutation, useQuery } from "@apollo/client";
import { adminTeamSchema } from "@roadflex/constants";
import {
  CREATE_ADMIN,
  DELETE_ADMIN,
  READ_ADMINS,
  UPDATE_ADMIN,
} from "@roadflex/graphql";
import { AdminType } from "@roadflex/types";
import { useFormik } from "formik";

type UseCreateAdminProps = {
  onSubmit: (res: any, err: any) => void;
  initialValues: AdminType;
};

type UpdateAdminProps = {
  onSubmit: (res: any, err: any) => void;
  initialValues: AdminType;
};

type DeleteAdminProps = {
  onSubmit: (res: any, err: any) => void;
  initialValues: { idList: string[] };
};

export const useCreateAdmin = ({
  onSubmit,
  initialValues,
}: UseCreateAdminProps) => {
  const [createAdminFn] = useMutation<{
    createAdmin: {
      code: string;
      message: string;
    };
  }>(CREATE_ADMIN);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    resetForm,
    setFieldValue,
    errors,
    touched,
    ...rest
  } = useFormik({
    initialValues,
    validationSchema: adminTeamSchema,
    onSubmit: async (value) => {
      try {
        const res = await createAdminFn({
          variables: { data: value },
        });
        if (res?.data?.createAdmin) {
          onSubmit(res?.data?.createAdmin, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    resetForm,
    setFieldValue,
    errors,
    touched,
    ...rest,
  };
};

export const useReadAdmin = () => {
  const {
    data: adminListData,
    refetch: adminListRefetch,
    loading: adminListLoading,
    ...rest
  } = useQuery<{
    readAdmins: {
      adminList: AdminType[];
      code: string;
      message: string;
    };
  }>(READ_ADMINS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });
  return {
    adminListData,
    adminListRefetch,
    adminListLoading,
    ...rest,
  };
};

export const useUpdateAdmin = ({
  onSubmit,
  initialValues,
}: UpdateAdminProps) => {
  const [updateAdminFn] = useMutation<{
    updateAdmin: {
      code: string;
      message: string;
    };
  }>(UPDATE_ADMIN);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    resetForm,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (value) => {
      try {
        const res = await updateAdminFn({
          variables: { data: value },
        });
        if (res?.data?.updateAdmin) {
          onSubmit(res?.data?.updateAdmin, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    resetForm,
    setFieldValue,
    errors,
    touched,
  };
};

export const useDeleteAdmin = ({
  onSubmit,
  initialValues,
}: DeleteAdminProps) => {
  const [deleteAdminFn] = useMutation<{
    deleteAdmin: {
      code: string;
      message: string;
    };
  }>(DELETE_ADMIN);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (value) => {
      try {
        const res = await deleteAdminFn({
          variables: { data: value },
        });
        if (res?.data?.deleteAdmin) {
          onSubmit(res?.data?.deleteAdmin, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};
