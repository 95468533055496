/** @format */

/* eslint-disable-next-line */
export interface ReactContextsProps {}

export function ReactContexts(props: ReactContextsProps) {
  return (
    <div>
      <h1>Welcome to ReactContexts!</h1>
    </div>
  );
}

export default ReactContexts;
