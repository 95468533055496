/** @format */

import {
  BoltIcon,
  BoltSlashIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { ENUM_COMMENT_TYPE } from "@prisma/client";
import {
  useCreateAdminComment,
  usePlatformDetect,
  useReadAdminActivityLogs,
  useReadAdminComment,
  useUpdateAdminComment,
} from "@roadflex/react-hooks";
import {
  AdminActivityLogsType,
  AdminType,
  CommentsType,
} from "@roadflex/types";
import { capitalizeFirstLetter, humanizeEnum } from "@roadflex/utils";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";
import { useState } from "react";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../../buttons";
import { Select } from "../../dropdowns";
import { TextAreaInput } from "../../inputs";
import { Loader } from "../../loader";
import AdminModalLoader from "../../loader/admin-modal-loader";
import { Toast } from "../../toast-message/toast";

type AdminCommentActivityModalProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  userData: {
    userId: string;
    email: string;
  };
  options: ENUM_COMMENT_TYPE[];
  showActivityLogs: boolean;
  showComments: boolean;
  addComments: boolean;
  adminDateTimeTemplate: (date: Date) => JSX.Element;
  admin?: AdminType;
};

export const AdminCommentActivityModal = ({
  open,
  setOpen,
  userData,
  options,
  showActivityLogs,
  showComments,
  addComments,
  adminDateTimeTemplate,
  admin,
}: AdminCommentActivityModalProps) => {
  const [adminCommentType, setAdminCommentType] = useState<ENUM_COMMENT_TYPE>(
    ENUM_COMMENT_TYPE.SUPPORT,
  );
  const { isMobile } = usePlatformDetect();

  const renderFooter = (
    <div className="flex flex-row justify-end pt-5 align-middle">
      <Button
        variant={ButtonVariant.GrayOutline}
        size={ButtonSize.AppSize}
        onClick={() => {
          setOpen(false);
        }}
      >
        Close
      </Button>
    </div>
  );

  const {
    adminActivityLogsData,
    adminActivityLogsRefetch,
    adminActivityLogsLoading,
  } = useReadAdminActivityLogs(
    admin ? { adminId: admin?.id } : { email: userData?.email },
  );
  const { commentsData, refetch, loading } = useReadAdminComment({
    email: userData?.email,
  });

  const adminActivityLogs: AdminActivityLogsType[] =
    adminActivityLogsData?.readAdminActivityLogs?.adminActivityLogs || [];

  const comments: CommentsType[] =
    commentsData?.readAdminComments?.comments || [];

  const {
    createCommentHandleSubmit,
    handleChange,
    values,
    handleBlur,
    isSubmitting,
    errors,
    touched,
    resetForm,
  } = useCreateAdminComment({
    initialValues: {
      userId: userData?.userId,
      comments: "",
      type: options.includes(ENUM_COMMENT_TYPE.SUPPORT)
        ? ENUM_COMMENT_TYPE.SUPPORT
        : options.includes(ENUM_COMMENT_TYPE.COLLECTIONS)
        ? ENUM_COMMENT_TYPE.COLLECTIONS
        : ENUM_COMMENT_TYPE.DEPOSIT,
    },
    onSubmit(res, err) {
      if (err) {
        Toast({ type: "error", message: err.message });
        return;
      }
      if (res?.code === "200") {
        Toast({ type: "success", message: res.message });
      } else {
        Toast({ type: "error", message: res.message });
      }
      resetForm();
    },
  });

  const {
    updateCommentSetFieldValue,
    updateCommentHandleSubmit,
    updateCommentIsSubmitting,
  } = useUpdateAdminComment({
    initialValues: {
      commentId: "",
      pin: false,
    },
    onSubmit(res, err) {
      if (err) {
        Toast({ type: "error", message: err.message });
      }
      if (res?.code === "200") {
        Toast({ type: "success", message: res.message });
        refetch();
      } else {
        Toast({ type: "error", message: res.message });
      }
    },
  });

  return (
    <div>
      <Dialog
        header={
          <div className="text-base lg:text-lg">
            {`User `}
            <em className="text-brown-500">{userData.email}</em>
          </div>
        }
        style={{
          width: isMobile() ? "90vw" : "70vw", // Adjusted for mobile
          fontFamily: "Inter",
        }}
        visible={open}
        onHide={() => setOpen(false)}
        closeOnEscape={true}
        closable={false}
        footer={renderFooter}
      >
        <AdminModalLoader
          showLoading={updateCommentIsSubmitting || isSubmitting}
        />
        <div className="space-y-3">
          {showActivityLogs && (
            <div className="mb-6 border-b border-brown-500">
              <div className="text-lg font-medium text-gray-900">
                Admin Activity
              </div>
              <div className="mt-2 border-t border-gray-200">
                {adminActivityLogsLoading ? (
                  <div className="flex justify-center">
                    <Loader></Loader>
                  </div>
                ) : (
                  <div>
                    <ul className="mt-2 border-gray-200">
                      {adminActivityLogs && adminActivityLogs?.length === 0 ? (
                        <li className="py-3 border-b border-gray-200">
                          <p className="text-sm text-gray-500">
                            No activity data
                          </p>
                        </li>
                      ) : null}
                      {adminActivityLogs &&
                        adminActivityLogs.map(
                          (
                            activityObj: AdminActivityLogsType,
                            index: number,
                          ) => {
                            return (
                              <li
                                className="py-3 border-b border-gray-200"
                                key={index}
                              >
                                <div
                                  className={`flex ${
                                    isMobile()
                                      ? "flex-col items-start"
                                      : "items-center"
                                  } justify-between`}
                                >
                                  <dt className="mr-4 text-sm font-medium text-gray-700">
                                    {activityObj?.admin?.firstName}{" "}
                                    {activityObj?.admin?.lastName} |{" "}
                                    <span className="text-gray-700">
                                      {capitalizeFirstLetter(
                                        activityObj.activityType
                                          .toLowerCase()
                                          .replace(/_/g, " "),
                                      )}
                                    </span>
                                  </dt>
                                  <dd className="text-sm font-normal text-gray-700">
                                    {adminDateTimeTemplate(
                                      activityObj.createdAt,
                                    )}
                                  </dd>
                                </div>
                                <p className="mt-2 text-sm font-medium text-gray-900">
                                  {activityObj.activity}
                                </p>
                              </li>
                            );
                          },
                        )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          )}
          {showComments && (
            <div>
              <div className="font-medium text-gray-900">Admin Comments</div>
              <div className="p-2 mt-4 border-2 border-brown-800">
                <div className="flex items-center justify-start">
                  <BoltIcon
                    className="flex-shrink-0 w-4 h-4"
                    aria-hidden="true"
                  />
                  <div className="ml-2">Pinned</div>
                </div>
                {loading ? (
                  <div className="flex justify-center">
                    <Loader></Loader>
                  </div>
                ) : (
                  <ul className="mt-2 border-gray-200">
                    {comments &&
                    comments?.filter((data: CommentsType) => data?.pin === true)
                      .length === 0 ? (
                      <li className="py-3 border-b border-gray-200">
                        <p className="text-sm text-gray-500">
                          No comments pinned
                        </p>
                      </li>
                    ) : null}
                    {comments
                      ?.filter((data: CommentsType) => data?.pin === true)
                      .map((commentObj: CommentsType, index: number) => {
                        return (
                          <li
                            className="py-3 border-b border-gray-200"
                            key={index}
                          >
                            <div className="flex items-center justify-between">
                              <dt className="mr-4 text-sm font-medium text-gray-500">
                                {commentObj.adminFname} {commentObj.adminLname}{" "}
                                | {commentObj.type}
                              </dt>
                              <dd className="text-sm font-normal text-gray-500">
                                {adminDateTimeTemplate(commentObj.createdAt)}
                              </dd>
                            </div>
                            <p className="mt-2 text-sm font-medium text-gray-900">
                              {commentObj.comments}
                            </p>
                            <div className="flex items-center justify-end text-sm text-brown-500">
                              <Button
                                variant={ButtonVariant.Transparent}
                                size={ButtonSize.Small}
                                loading={isSubmitting}
                                disabled={isSubmitting}
                                className=""
                                onClick={() => {
                                  updateCommentSetFieldValue(
                                    "commentId",
                                    commentObj.id,
                                  );
                                  updateCommentSetFieldValue("pin", false);
                                  updateCommentHandleSubmit();
                                }}
                              >
                                <BoltSlashIcon
                                  className="flex-shrink-0 w-4 h-4"
                                  aria-hidden="true"
                                />
                                <div className="ml-2">Unpin</div>
                              </Button>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                )}
              </div>
              <div className="p-2 my-4">
                <div className="flex w-full gap-2">
                  <Button
                    variant={ButtonVariant.Gray}
                    size={ButtonSize.AppSize}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    onClick={() => {
                      setAdminCommentType(ENUM_COMMENT_TYPE.SUPPORT);
                    }}
                    className={`${
                      adminCommentType === ENUM_COMMENT_TYPE.SUPPORT
                        ? "bg-scarpa-flow-100 text-white"
                        : "border-gray-300 bg-white text-gray-700"
                    } relative inline-flex items-center px-4 justify-center w-full py-2 rounded-l-md border text-sm font-medium`}
                  >
                    Support
                  </Button>
                  <Button
                    variant={ButtonVariant.Gray}
                    size={ButtonSize.AppSize}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    onClick={() => {
                      setAdminCommentType(ENUM_COMMENT_TYPE.COLLECTIONS);
                    }}
                    className={`${
                      adminCommentType === ENUM_COMMENT_TYPE.COLLECTIONS
                        ? "bg-scarpa-flow-100 text-white"
                        : "border-gray-300 bg-white text-gray-700"
                    } -ml-px relative inline-flex justify-center items-center w-full px-4 py-2 border text-sm font-medium`}
                  >
                    Collections
                  </Button>
                  <Button
                    variant={ButtonVariant.Gray}
                    size={ButtonSize.AppSize}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    onClick={() => {
                      setAdminCommentType(ENUM_COMMENT_TYPE.DEPOSIT);
                    }}
                    className={`${
                      adminCommentType === ENUM_COMMENT_TYPE.DEPOSIT
                        ? "bg-scarpa-flow-100 text-white"
                        : "border-gray-300 bg-white text-gray-700"
                    } -ml-px relative inline-flex justify-center items-center w-full px-4 py-2 rounded-r-md border text-sm font-medium`}
                  >
                    Deposit
                  </Button>
                </div>
                {loading ? (
                  <div className="flex justify-center">
                    <Loader></Loader>
                  </div>
                ) : (
                  <ul className="mt-2 border-gray-200 ">
                    {comments?.filter(
                      (data: CommentsType) =>
                        data?.type === adminCommentType && data?.pin === false,
                    ).length === 0 ? (
                      <li className="py-3 border-b border-gray-200">
                        <p className="text-sm text-gray-500">No comments</p>
                      </li>
                    ) : null}
                    {comments
                      ?.filter(
                        (data: CommentsType) =>
                          data?.type === adminCommentType &&
                          data?.pin === false,
                      )
                      .map((commentObj: CommentsType, index: number) => {
                        return (
                          <li
                            className="py-3 border-b border-gray-200"
                            key={index}
                          >
                            <div className="flex items-center justify-between">
                              <dt className="mr-4 text-sm font-medium text-gray-500">
                                {commentObj.adminFname} {commentObj.adminLname}
                              </dt>
                              <dd className="text-sm font-normal text-gray-500">
                                {adminDateTimeTemplate(commentObj.createdAt)}
                              </dd>
                            </div>
                            <p className="mt-2 text-sm font-medium text-gray-900">
                              {commentObj.comments}
                            </p>
                            <div className="flex items-center justify-end text-sm text-brown-500">
                              <Button
                                variant={ButtonVariant.Transparent}
                                size={ButtonSize.Small}
                                loading={isSubmitting}
                                disabled={isSubmitting}
                                className=""
                                onClick={() => {
                                  updateCommentSetFieldValue(
                                    "commentId",
                                    commentObj.id,
                                  );
                                  updateCommentSetFieldValue("pin", true);
                                  updateCommentHandleSubmit();
                                }}
                              >
                                <BoltIcon
                                  className="flex-shrink-0 w-4 h-4"
                                  aria-hidden="true"
                                />
                                <div className="ml-2">Pin</div>
                              </Button>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                )}
              </div>
            </div>
          )}
          {addComments && (
            <form onSubmit={createCommentHandleSubmit} className="w-full">
              <div className="flex">
                <div className="w-full mt-4">
                  <div className="mb-1 text-sm whitespace-nowrap">Comment</div>
                  <div className="relative">
                    <TextAreaInput
                      name="comments"
                      value={values.comments}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={classNames(
                        touched.comments && errors?.comments
                          ? "border-red-500 focus:border-red-500 border"
                          : "",
                        "block w-full h-auto pl-3 pr-8 py-2 border rounded focus:outline-none focus:ring-0 focus:shadow-none",
                      )}
                    />
                    {touched.comments && errors?.comments && (
                      <div>
                        <Tooltip
                          target=".comment-error"
                          position="top"
                          className="red-tooltip"
                          content={errors.comments}
                        ></Tooltip>

                        <div className="absolute h-[38px] bottom-0 right-0 pr-3 flex items-center cursor-pointer">
                          <ExclamationCircleIcon
                            className="w-5 h-5 text-red-500 comment-error"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex justify-between mt-4 flex-nowrap">
                    <div className="relative w-full">
                      <div className="relative flex justify-between py-1 text-sm font-medium border border-gray-300 rounded-md shadow-sm focus-within:ring-1 focus-within:ring-brown-600 focus-within:border-brown-600">
                        <div className="block w-full">
                          <Select
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name={`type`}
                            value={values.type}
                            className="border-none"
                            options={Object.keys(ENUM_COMMENT_TYPE)
                              .filter((key) =>
                                options.includes(key as ENUM_COMMENT_TYPE),
                              )
                              .map((key) => {
                                return {
                                  label: humanizeEnum(key),
                                  value: key,
                                  key: key,
                                  disabled: false,
                                };
                              })}
                          ></Select>
                        </div>
                      </div>
                      {touched.type && errors?.type && (
                        <div>
                          <Tooltip
                            target=".type-error"
                            position="top"
                            className="red-tooltip"
                            content={errors.type}
                          ></Tooltip>

                          <div className="absolute h-[44px] bottom-0 right-0 pr-8 flex items-center cursor-pointer">
                            <ExclamationCircleIcon
                              className="w-5 h-5 text-red-500 type-error"
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <Button
                      type={ButtonType.Submit}
                      variant={ButtonVariant.Orange}
                      size={ButtonSize.AppSize}
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      className="ml-3"
                    >
                      Add
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </Dialog>
    </div>
  );
};
