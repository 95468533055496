/** @format */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useMutation } from "@apollo/client";
import { ENUM_NOTIFICATION_TYPE, ENUM_SENDGRID_TEMPLATE } from "@prisma/client";
import { adminNotificationsWorkFlowSchema } from "@roadflex/constants";
import {
  CREATE_UPDATE_NOTIFICATION_WORKFLOW,
  READ_NOTIFICATION_WORKFLOW,
} from "@roadflex/graphql";
import { useTestEmailOrSms } from "@roadflex/react-hooks";
import { NotificationWorkflow, SendGridTemplateDetails } from "@roadflex/types";
import { humanize } from "@roadflex/utils";
import { phoneFormatter } from "@roadflex/web-lib";
import classNames from "classnames";
import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import {
  TabPanel,
  TabPanelHeaderTemplateOptions,
  TabView,
} from "primereact/tabview";
import { useEffect } from "react";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../../buttons";
import { CustomMultiSelect, Select } from "../../dropdowns";
import { TextAreaInput, TextInput } from "../../inputs";
import { Toast } from "../../toast-message/toast";

export type AddWorkflowModalProps = {
  sendGridTemplates: SendGridTemplateDetails[];
  addedEmailAccounts: {
    id: string;
    email: string;
  }[];
  workflowData: NotificationWorkflow[];
  selectedWorkflow: NotificationWorkflow;
  workFlowModal: boolean;
  setWorkFlowModal: (value: boolean) => void;
};

export const AddWorkflowModal = ({
  sendGridTemplates,
  addedEmailAccounts,
  workflowData,
  selectedWorkflow,
  workFlowModal,
  setWorkFlowModal,
}: AddWorkflowModalProps) => {
  const initValues = {
    id: selectedWorkflow.id,
    day: selectedWorkflow.day,
    notificationType: selectedWorkflow.notificationType,
    emailId: selectedWorkflow.emailId,
    smsTemplate: selectedWorkflow.smsTemplate,
    variables: selectedWorkflow.variables,
    sendgridTemplateType: selectedWorkflow.sendgridTemplateType,
    workflowType: selectedWorkflow.workflowType,
  };
  const [saveWorkFlowFn, { loading: submitting }] = useMutation(
    CREATE_UPDATE_NOTIFICATION_WORKFLOW,
    {
      refetchQueries: [READ_NOTIFICATION_WORKFLOW],
    },
  );
  const {
    values: testEmailOrSmsValues,
    handleSubmit: testEmailOrSmsHandleSubmit,
    setFieldValue: testEmailOrSmsSetFieldValue,
    isSubmitting: testEmailOrSmsIsSubmitting,
    touched: testEmailOrSmsTouched,
    errors: testEmailOrSmsErrors,
    handleBlur: testEmailOrSmsHandleBlur,
  } = useTestEmailOrSms({
    initialValues: {
      mode: "Email",
      phoneNumber: "",
      smsTemplate: "",
      emailTemplateId: "",
      emailList: [""],
      variables: {},
      emailId: "",
    },
    onSubmit(res: any, err: any) {
      if (err) {
        Toast({ type: "error", message: err.message });
      }
      if (res) {
        Toast({ type: "success", message: res.message });
      }
    },
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    submitForm,
    setFieldValue,
    values,
    touched,
    isSubmitting,
    errors,
    setValues,
    setFieldTouched,
  } = useFormik({
    initialValues: initValues,
    validationSchema: adminNotificationsWorkFlowSchema,
    onSubmit: async (value) => {
      const { ...rest } = value;
      try {
        await saveWorkFlowFn({
          variables: {
            data: {
              ...rest,
              day: Number(rest.day),
            },
          },
        });
        Toast({ type: "success", message: "Workflow saved successfully" });
        setWorkFlowModal(false);
      } catch (err: any) {
        Toast({ type: "error", message: err.message });
      }
    },
  });

  const initializeVariables = () => {
    const matchingTemplate = sendGridTemplates.find(
      (sgTemplate) =>
        sgTemplate.sendgridTemplateType === values.sendgridTemplateType,
    );
    // Function to initialize variables based on the matching template
    if (matchingTemplate) {
      const initialVars: { [id: string]: string } = {};
      matchingTemplate.variableNames.forEach((variable: string) => {
        initialVars[variable] = values.variables[variable] || "";
      });

      setValues((prevValues) => ({
        ...prevValues,
        variables: initialVars,
      }));
    }
  };

  useEffect(() => {
    // Initialize variables on component mount
    initializeVariables();
  }, [selectedWorkflow.sendgridTemplateType]);

  const notificationsOptions = Object.keys(ENUM_NOTIFICATION_TYPE).map(
    (key) => {
      return {
        label: humanize(key),
        value: key,
      };
    },
  );

  const onTestEmail = async () => {
    try {
      testEmailOrSmsSetFieldValue("mode", "Email");
      testEmailOrSmsSetFieldValue("variables", values.variables);
      testEmailOrSmsSetFieldValue("emailList", testEmailOrSmsValues.emailList);
      testEmailOrSmsSetFieldValue("emailId", values.emailId);
      testEmailOrSmsHandleSubmit();
    } catch (err: any) {
      Toast({ type: "error", message: err.message });
    }
  };

  const onTestSms = async () => {
    try {
      testEmailOrSmsSetFieldValue("mode", "SMS");
      testEmailOrSmsSetFieldValue("smsTemplate", values.smsTemplate);
      testEmailOrSmsSetFieldValue(
        "phoneNumber",
        testEmailOrSmsValues.phoneNumber.trim().match(/\d+/g)?.join(""),
      );
      testEmailOrSmsHandleSubmit();
    } catch (err: any) {
      Toast({ type: "error", message: err.message });
    }
  };

  const tabHeader1Template = (options: TabPanelHeaderTemplateOptions) => {
    return (
      <div
        className={classNames(
          touched.emailId && errors?.emailId ? "text-red-500" : "",
          options.selected ? "border-b-2 border-orange-500" : "",
          "flex align-items-center p-3",
        )}
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          options.onClick(e);
          testEmailOrSmsSetFieldValue("mode", "Email");
        }}
      >
        Email Settings
        {values.notificationType.includes(ENUM_NOTIFICATION_TYPE.EMAIL) && "*"}
      </div>
    );
  };

  const tabHeader2Template = (options: TabPanelHeaderTemplateOptions) => {
    return (
      <div
        className={classNames(
          touched.smsTemplate && errors?.smsTemplate ? "text-red-500" : "",
          options.selected ? "border-b-2 border-orange-500" : "",
          "flex align-items-center p-3",
        )}
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          options.onClick(e);
          testEmailOrSmsSetFieldValue("mode", "SMS");
        }}
      >
        SMS Settings
        {values.notificationType.includes(ENUM_NOTIFICATION_TYPE.SMS) && "*"}
      </div>
    );
  };
  const footer = (
    <div className="flex flex-row justify-end">
      <Button
        variant={ButtonVariant.Gray}
        loading={submitting}
        className="ml-3"
        disabled={isSubmitting || submitting}
        onClick={() => {
          handleSubmit();
        }}
      >
        Save
      </Button>
    </div>
  );

  const renderHeader = () => {
    return <div className="text-base">Add/Edit Step</div>;
  };
  return (
    <Dialog
      header={renderHeader}
      visible={workFlowModal}
      style={{ width: "50vw" }}
      footer={footer}
      onHide={() => setWorkFlowModal(false)}
    >
      <form className="w-full">
        <div className="">
          <div className="relative w-full mt-4">
            <TextInput
              label="Due Day"
              name="day"
              value={values.day}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.day && Boolean(errors.day)}
              errorMessage={errors.day as string}
              touched={touched.day as boolean}
              disabled={selectedWorkflow.id.length > 0}
              variant="medium"
              required
            ></TextInput>
          </div>
          <div className="relative w-full mt-4">
            <CustomMultiSelect
              label="Notification Type"
              required
              value={values.notificationType}
              options={notificationsOptions}
              optionLabel="label"
              optionValue="value"
              onChange={(e) => {
                setFieldValue("notificationType", e.value);
              }}
              maxSelectedLabels={1}
            ></CustomMultiSelect>
          </div>
          <div className="relative mt-4 ">
            <div className="mb-2 text-sm whitespace-nowrap">
              Variables that can be used in the template
            </div>
            <div className="p-2 border rounded-md border-brown-500 h-[37.6px] text-[14px]">
              billingAmount, invoiceNumber, companyName, firstName
            </div>
          </div>

          <div className="mt-4">
            <TabView>
              <TabPanel headerTemplate={tabHeader1Template} className="p-0!">
                <>
                  <Select
                    label="Send from email"
                    required={values.notificationType.includes(
                      ENUM_NOTIFICATION_TYPE.EMAIL,
                    )}
                    options={
                      addedEmailAccounts
                        ? addedEmailAccounts.map((m) => {
                            return {
                              label: m.email,
                              value: m.email,
                              disabled: false,
                            };
                          })
                        : []
                    }
                    optionLabel="label"
                    optionValue="value"
                    onChange={(e) => {
                      setFieldValue("emailId", e.value);
                    }}
                    onBlur={() => setFieldTouched("emailId", true)}
                    name="emailId"
                    value={values.emailId}
                    placeholder="-- Select Email --"
                    touched={touched?.emailId as boolean}
                    error={touched?.emailId && Boolean(errors?.emailId)}
                    errorMessage={errors?.emailId as string}
                  ></Select>

                  <div className="mt-4">
                    <Select
                      label="SendGrid Template"
                      required={values.notificationType.includes(
                        ENUM_NOTIFICATION_TYPE.EMAIL,
                      )}
                      options={Object.keys(ENUM_SENDGRID_TEMPLATE).map((m) => {
                        return {
                          label: m,
                          value: m,
                          disabled: false,
                        };
                      })}
                      optionLabel="label"
                      optionValue="value"
                      onChange={(e) => {
                        setFieldValue("sendgridTemplateType", e.value);
                      }}
                      onBlur={() =>
                        setFieldTouched("sendgridTemplateType", true)
                      }
                      name="sendgridTemplateType"
                      value={values.sendgridTemplateType}
                      placeholder="-- Select Notification Type --"
                      disabled
                      touched={touched?.sendgridTemplateType as boolean}
                      error={
                        touched?.sendgridTemplateType &&
                        Boolean(errors?.sendgridTemplateType)
                      }
                      errorMessage={errors?.sendgridTemplateType as string}
                    ></Select>
                  </div>

                  <div className="relative w-full mt-4 mb-2">
                    <div className="text-base font-bold whitespace-nowrap">
                      Email Variables
                    </div>
                  </div>
                  {values.variables &&
                    Object.keys(values.variables).map((variable, index) => (
                      <div key={index} className="relative w-full mb-4">
                        <TextInput
                          label={variable}
                          name={`variables.${variable}`}
                          value={values.variables[variable]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="medium"
                        ></TextInput>
                      </div>
                    ))}

                  <div className="flex items-center mt-8">
                    <div className="w-full">
                      <TextInput
                        type="email"
                        name="emailList"
                        value={testEmailOrSmsValues.emailList[0]}
                        onChange={(event) =>
                          testEmailOrSmsSetFieldValue("emailList", [
                            event?.target?.value,
                          ])
                        }
                        onBlur={testEmailOrSmsHandleBlur}
                        error={
                          testEmailOrSmsTouched.emailList &&
                          Boolean(testEmailOrSmsErrors.emailList)
                        }
                        errorMessage={testEmailOrSmsErrors?.emailList?.[0]}
                        touched={testEmailOrSmsTouched?.emailList}
                        placeholder="Enter email to test"
                        variant="medium"
                        required
                      ></TextInput>
                    </div>
                    <Button
                      type={ButtonType.Button}
                      variant={ButtonVariant.AppOrange}
                      size={ButtonSize.AppSize}
                      loading={testEmailOrSmsIsSubmitting}
                      className="ml-3"
                      disabled={
                        testEmailOrSmsIsSubmitting ||
                        !testEmailOrSmsValues.emailList
                      }
                      onClick={() => {
                        onTestEmail();
                      }}
                    >
                      Test
                    </Button>
                  </div>
                </>
              </TabPanel>
              <TabPanel
                headerTemplate={tabHeader2Template}
                headerClassName="flex align-items-center"
              >
                <>
                  <div className="relative w-full">
                    <TextAreaInput
                      label="SMS Template"
                      required={values.notificationType.includes(
                        ENUM_NOTIFICATION_TYPE.SMS,
                      )}
                      name="smsTemplate"
                      value={values.smsTemplate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched.smsTemplate as boolean}
                      error={
                        (touched.smsTemplate &&
                          Boolean(errors.smsTemplate)) as boolean
                      }
                      errorMessage={errors.smsTemplate as string}
                      rows={8}
                    ></TextAreaInput>
                  </div>
                  <div className="flex items-center mt-4">
                    <div className="relative w-full">
                      <TextInput
                        type="tel"
                        name="phoneNumber"
                        value={testEmailOrSmsValues.phoneNumber}
                        onChange={(event) =>
                          testEmailOrSmsSetFieldValue(
                            "phoneNumber",
                            phoneFormatter(event?.target?.value),
                          )
                        }
                        onBlur={testEmailOrSmsHandleBlur}
                        error={
                          testEmailOrSmsTouched.phoneNumber &&
                          Boolean(testEmailOrSmsErrors.phoneNumber)
                        }
                        errorMessage={testEmailOrSmsErrors.phoneNumber}
                        touched={testEmailOrSmsTouched.phoneNumber}
                        variant="medium"
                        placeholder="Enter number to test"
                      ></TextInput>
                    </div>
                    <Button
                      type={ButtonType.Button}
                      variant={ButtonVariant.AppOrange}
                      size={ButtonSize.AppSize}
                      loading={isSubmitting}
                      className="ml-3"
                      disabled={
                        isSubmitting || testEmailOrSmsValues.phoneNumber === ""
                      }
                      onClick={() => {
                        onTestSms();
                      }}
                    >
                      Test
                    </Button>
                  </div>
                </>
              </TabPanel>
            </TabView>
          </div>
        </div>
      </form>
    </Dialog>
  );
};
