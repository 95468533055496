/** @format */

export const removeAutoServiceShopsCategoryFromArray = (
  array: string[],
): string[] => {
  const finalArray = array.filter(function (value) {
    return value !== "auto_service_shops";
  });
  return finalArray;
};

export const addAutoServiceShopsCategoryToArray = (
  array: string[],
): string[] => {
  const finalArray = [...array];
  if (array.includes("auto_body_repair_shops")) {
    finalArray.push("auto_service_shops");
  }
  return finalArray;
};

export const removeElementsFromArray1NotContainedInArray2 = (
  array1: string[],
  array2: string[],
): string[] => {
  const valuesNotToDeleteSet = new Set(array2);
  const finalArray = array1.filter((name) => {
    return valuesNotToDeleteSet.has(name);
  });
  return finalArray;
};
