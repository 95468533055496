/** @format */
import { useMutation } from "@apollo/client";
import { DEFAULTED_USERS_EXPORT_DATA } from "@roadflex/graphql";

export const useGetDefaultedUsersExportData = () => {
  const [getDefaultedUsersExportData] = useMutation(
    DEFAULTED_USERS_EXPORT_DATA,
    {
      fetchPolicy: "no-cache",
    },
  );
  return { getDefaultedUsersExportData };
};
