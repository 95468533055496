/** @format */

import { DevicePhoneMobileIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Button, ButtonSize, ButtonVariant } from "@roadflex/react-components";
import { useReadCurrentAdmin } from "@roadflex/react-hooks";
import { usePlatformDetect } from "@roadflex/react-hooks-www";
import { useRouter } from "next/router";
import { useEffect } from "react";
import Header from "../header/header";
import Navigation from "../navigation/navigation";
import { AllRoutes } from "./helpers";

function Layout({ children }: { children: JSX.Element }): JSX.Element {
  const router = useRouter();
  const { data, refetch } = useReadCurrentAdmin();
  const { isMobile } = usePlatformDetect();

  useEffect(() => {
    refetch();
  }, [router?.asPath, refetch, data]);

  // current page info -> check helper file in layouts
  const curr = AllRoutes.find((f) => f.route === router?.route);

  if (curr?.dontShow) {
    return <>{children}</>;
  }
  if (curr?.authenticated && data?.readCurrentAdmin) {
    return (
      <>
        <div className="min-h-full">
          {/* Static sidebar for desktop */}
          <div className="hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0">
            <Navigation
              open={false}
              toggle={() => {
                /**/
              }}
            />
          </div>
          <div className="flex flex-col flex-1 min-h-screen bg-blue-100 lg:pl-64">
            <div className="relative z-10 flex flex-shrink-0 h-16 mb-5 bg-blue-100 border-b border-gray-200 lg:border-none">
              <Header />
            </div>
            {isMobile() && !curr.isMobileResponsive ? (
              <div className="flex flex-col items-center justify-center flex-1 h-full px-4 -mt-10">
                <div className="flex items-center justify-center ">
                  <div className="max-w-md p-8 text-center bg-white border border-gray-300 rounded-lg shadow-lg">
                    <div className="relative flex justify-center mx-auto mb-4">
                      <DevicePhoneMobileIcon className="flex w-10 h-10"></DevicePhoneMobileIcon>
                      <XMarkIcon className="absolute w-5 h-5 top-2"></XMarkIcon>
                    </div>
                    <h2 className="mb-4 text-2xl font-semibold text-gray-800">
                      Mobile Access Restricted
                    </h2>
                    <p className="mb-4 text-gray-600">
                      This page cannot be accessed on a mobile screen. Please
                      log into your account on the desktop to access this page.
                    </p>
                    <Button
                      variant={ButtonVariant.AppOrange}
                      onClick={() => {
                        router.push("/dashboard");
                      }}
                      className="mx-auto"
                      size={ButtonSize.Normal}
                    >
                      Back to Dashboard
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              children
            )}
          </div>
        </div>
      </>
    );
  }
  return <div>{children}</div>;
}

export default Layout;
