/** @format */
import { useQuery } from "@apollo/client";
import { READ_ADMIN_ACTIVITY_LOGS } from "@roadflex/graphql";
import { AdminActivityLogsType } from "@roadflex/types";

export const useReadAdminActivityLogs = ({
  email,
  adminId,
}: {
  email?: string;
  adminId?: string;
}) => {
  const queryObject = {
    ...(email ? { email } : {}),
    ...(adminId ? { adminId } : {}),
  };

  const {
    data: adminActivityLogsData,
    refetch: adminActivityLogsRefetch,
    loading: adminActivityLogsLoading,
    ...rest
  } = useQuery<{
    readAdminActivityLogs: {
      code: string;
      message: string;
      adminActivityLogs: AdminActivityLogsType[];
    };
  }>(READ_ADMIN_ACTIVITY_LOGS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: { data: queryObject },
  });
  return {
    adminActivityLogsData,
    adminActivityLogsRefetch,
    adminActivityLogsLoading,
    ...rest,
  };
};
