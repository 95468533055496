/** @format */
import { useRouter } from "next/router";
import { useEffect } from "react";

const Redirect = ({ path }: { path: string } = { path: "/" }) => {
  const router = useRouter();

  useEffect(() => {
    router.push(path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default Redirect;
