/** @format */
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GET_ADMIN_AUTO_RELOAD_DATA,
  GET_ADMIN_USER_REPAYMENT_DATA,
  READ_ADMIN_DASHBOARD,
  READ_ADMIN_DASHBOARD_CUSTOMER_STATISTICS,
  READ_ADMIN_SYSTEM_HEALTH_CHECK,
} from "@roadflex/graphql";

export function useReadAdminDashboard() {
  const { data, ...rest } = useQuery(READ_ADMIN_DASHBOARD, {
    fetchPolicy: "no-cache",
  });
  return { data, ...rest };
}

export function useReadAdminDashboardCustomersStatistics() {
  const [
    readAdminDashboardCustomersStatistics,
    {
      data: customerStatisticsData,
      loading: customerStatisticsLoading,
      called: customerStatisticsCalled,
      ...rest
    },
  ] = useLazyQuery(READ_ADMIN_DASHBOARD_CUSTOMER_STATISTICS, {
    fetchPolicy: "no-cache",
  });

  return {
    readAdminDashboardCustomersStatistics,
    customerStatisticsData,
    customerStatisticsLoading,
    customerStatisticsCalled,
  };
}

export function useReadSystemHealthCheck() {
  const { data, refetch, ...rest } = useQuery(READ_ADMIN_SYSTEM_HEALTH_CHECK, {
    fetchPolicy: "no-cache",
  });
  return { data, refetch, ...rest };
}

export function useReadAdminDashboardAutoReloadData() {
  const [
    readAdminDashboardAutoReloadDataFn,
    {
      data: dashboardAutoReloadData,
      loading: dashboardAutoReloadLoading,
      called: dashboardAutoReloadCalled,
      ...rest
    },
  ] = useLazyQuery(GET_ADMIN_AUTO_RELOAD_DATA, {
    variables: { data: { enabled: true } },
    fetchPolicy: "no-cache",
  });
  return {
    readAdminDashboardAutoReloadDataFn,
    dashboardAutoReloadData,
    dashboardAutoReloadLoading,
    dashboardAutoReloadCalled,
  };
}

export function useReadAdminUserRepaymentData() {
  const [
    readAdminUserRepaymentDataFn,
    {
      data: userRepaymentData,
      loading: userRepaymentLoading,
      called: userRepaymentCalled,
      ...rest
    },
  ] = useLazyQuery(GET_ADMIN_USER_REPAYMENT_DATA, {
    fetchPolicy: "no-cache",
  });
  return {
    readAdminUserRepaymentDataFn,
    userRepaymentData,
    userRepaymentLoading,
    userRepaymentCalled,
  };
}
