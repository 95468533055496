/** @format */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from "@apollo/client";
import { ADMIN_DOWNLOAD_EMAIL_HISTORY_LOGS } from "@roadflex/graphql";
import { convertToUTC } from "@roadflex/utils";
import { Calendar, CalendarChangeParams } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useRef, useState } from "react";
import { nameFilterTemplate } from "../../datatable";
import { newPaginationTemplate } from "../../datatable/pagination-templates/new-pagination-template";
import { Toast } from "../../toast-message/toast";

export interface EmailHistoryModalProps {
  openEmailHistoryModal: boolean;
  setOpenEmailHistoryModal: any;
  variablesTemplate: any;
  createdAtTemplate: any;
  resultTemplate: any;
}

export const EmailHistoryModal = ({
  openEmailHistoryModal,
  setOpenEmailHistoryModal,
  variablesTemplate,
  createdAtTemplate,
  resultTemplate,
}: EmailHistoryModalProps) => {
  const [downloadEmailsDate, setDownloadEmailsDate] = useState<
    Date | Date[] | undefined
  >(undefined);
  const [submittingRequest, setsubmittingRequest] = useState(false);
  const [emailsData, setEmailsData] = useState([]);
  const [AdminDownloadEmails] = useMutation(ADMIN_DOWNLOAD_EMAIL_HISTORY_LOGS);

  const handleDownloadEmails = (date: Date | Date[]) => {
    if (!Array.isArray(date) && date) {
      setsubmittingRequest(true);
      AdminDownloadEmails({
        variables: {
          data: { downloadLogsDate: convertToUTC(date) },
        },
      })
        .then(({ data }: any) => {
          if (data?.adminDownloadEmailHistoryLogs?.code === "200") {
            let mappedEmails = [];
            mappedEmails =
              data?.adminDownloadEmailHistoryLogs?.emailHistoryLogs || [];
            setEmailsData(mappedEmails);
          } else {
            setEmailsData([]);
            Toast({
              type: "error",
              message: data?.adminDownloadEmailHistoryLogs?.message,
            });
          }
          setsubmittingRequest(false);
        })
        .catch((err) => {
          setEmailsData([]);
          Toast({ type: "error", message: err.message });
          setsubmittingRequest(false);
        });
    }
  };

  const dt = useRef(null);

  const renderFooter = () => {
    return <div></div>;
  };

  return (
    <Dialog
      header={
        <div className="flex flex-col flex-wrap justify-start w-full col-span-1 gap-20 sm:w-auto sm:flex-row">
          <div className="flex items-center">{`View archived emails`}</div>
          <div className="flex items-center justify-start col-span-1 gap-8 text-base ">
            <div className="flex items-center justify-start col-span-1 gap-2">
              <label htmlFor="monthpicker">Select Year & Month</label>
              <Calendar
                id="monthpicker"
                className="custom-calendar"
                panelClassName="custom-calendar-panel"
                value={downloadEmailsDate}
                onChange={(e: CalendarChangeParams) => {
                  if (e.value) {
                    setDownloadEmailsDate(e.value as Date | Date[]);
                    handleDownloadEmails(e.value as Date | Date[]);
                  }
                }}
                view="month"
                dateFormat="mm/yy"
              />
            </div>
          </div>
        </div>
      }
      visible={openEmailHistoryModal}
      style={{ width: "80vw", height: "80vh", fontFamily: "Inter" }}
      footer={renderFooter()}
      onHide={() => setOpenEmailHistoryModal(false)}
    >
      <div className="border-b border-gray-200 shadow sm:rounded-md">
        <DataTable
          size="small"
          // exportFilename={`${exportFileName}Emails_${downloadEmailsDate}`}
          ref={dt}
          stripedRows
          value={emailsData}
          paginator
          rows={10}
          dataKey="id"
          sortField="createdAt"
          sortOrder={-1}
          paginatorTemplate={newPaginationTemplate}
          rowsPerPageOptions={[10, 20, 50]}
          responsiveLayout="scroll"
          emptyMessage="No available records"
          loading={submittingRequest}
          style={{ fontFamily: "Inter" }}
          className="!bg-white custom-table !text-brown-500 !border-brown-500"
        >
          <Column
            filter
            sortable
            filterPlaceholder="Search by email"
            field="email"
            header="Email"
            className="text-sm 5xl:text-base"
            showFilterMenuOptions={false}
            showFilterMatchModes={false}
            filterMenuStyle={{
              width: "14rem",
            }}
            bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base capitalize"
            headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
            filterMenuClassName="custom-filter"
            filterElement={(e) => nameFilterTemplate(e)}
          />
          <Column
            filter
            sortable
            filterPlaceholder="Search by status"
            field="status"
            header="Status"
            className="text-sm 5xl:text-base"
            showFilterMenuOptions={false}
            showFilterMatchModes={false}
            filterMenuStyle={{
              width: "14rem",
            }}
            bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base capitalize"
            headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
            filterMenuClassName="custom-filter"
            filterElement={(e) => nameFilterTemplate(e)}
          />
          <Column
            filter
            sortable
            filterPlaceholder="Search by email type"
            field="emailType"
            header="Type"
            className="text-sm 5xl:text-base"
            showFilterMenuOptions={false}
            showFilterMatchModes={false}
            filterMenuStyle={{
              width: "14rem",
            }}
            bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base capitalize"
            headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
            filterMenuClassName="custom-filter"
            filterElement={(e) => nameFilterTemplate(e)}
          />
          <Column
            filter
            sortable
            filterPlaceholder="Search by message ID"
            field="messageId"
            header="Message ID"
            className="text-sm 5xl:text-base"
            showFilterMenuOptions={false}
            showFilterMatchModes={false}
            filterMenuStyle={{
              width: "14rem",
            }}
            bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base capitalize"
            headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
            filterMenuClassName="custom-filter"
            filterElement={(e) => nameFilterTemplate(e)}
          />
          <Column
            sortable
            dataType="date"
            field="createdAt"
            header="Time (Your timezone)"
            body={createdAtTemplate}
            className="text-sm 5xl:text-base"
            bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base capitalize"
            headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
          />
          <Column
            header="Variables"
            className="text-sm 5xl:text-base"
            bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base capitalize"
            headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
            body={variablesTemplate}
          />
          <Column
            header="Result"
            className="text-sm 5xl:text-base"
            bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base capitalize"
            headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
            body={resultTemplate}
          />
        </DataTable>
      </div>
    </Dialog>
  );
};
