/** @format */

import { deleteCookie, getCookie } from "@roadflex/web-lib";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import HeaderUI from "./header.ui";

/* eslint-disable-next-line */
export interface HeaderProps {}

export function Header(props: HeaderProps) {
  const router = useRouter();
  const [firstName, setFirstName] = useState<any>("");
  const [lastName, setLastName] = useState<any>("");

  const handleLogout = () => {
    localStorage.removeItem("adminToken");
    localStorage.removeItem("adminId");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    deleteCookie("adminToken");
    router.push("/");
  };

  useEffect(() => {
    if (!getCookie("adminToken")) {
      handleLogout();
    }
    setLastName(
      typeof window !== null ? localStorage.getItem("lastName") : "Admin",
    );
    setFirstName(
      typeof window !== null ? localStorage.getItem("firstName") : "User",
    );
  }, [router]);

  return <HeaderUI {...{ handleLogout, firstName, lastName }} />;
}

export default Header;
