/** @format */

export const AllRoutes = [
  {
    route: "/dashboard",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/onboard",
    authenticated: true,
    isMobileResponsive: false,
  },
  {
    route: "/subscribers",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/applications-pending-deposit",
    authenticated: true,
    isMobileResponsive: false,
  },
  {
    route: "/clients",
    authenticated: true,
    isMobileResponsive: false,
  },
  {
    route: "/global-settings",
    authenticated: true,
    isMobileResponsive: false,
  },
  {
    route: "/team",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/blogs",
    authenticated: true,
    isMobileResponsive: false,
  },
  {
    route: "/notifications",
    authenticated: true,
    isMobileResponsive: false,
  },
  {
    route: "/risk",
    authenticated: true,
    isMobileResponsive: false,
  },
  {
    route: "/scripts",
    authenticated: true,
    isMobileResponsive: false,
  },
  {
    route: "/data-analysis",
    authenticated: true,
    isMobileResponsive: false,
  },
  {
    route: "/payments",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/logs",
    authenticated: true,
    isMobileResponsive: false,
  },
  {
    route: "/customer-dashboard",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/collections",
    authenticated: true,
    isMobileResponsive: false,
  },
  {
    route: "/settings",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/",
    authenticated: false,
    dontShow: true,
    isMobileResponsive: false,
  },
  {
    route: "/signin",
    authenticated: false,
    isMobileResponsive: false,
  },
  {
    route: "/404",
    authenticated: false,
    dontShow: true,
    isMobileResponsive: false,
  },
];
