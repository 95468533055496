/** @format */
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_SMS_TEMPLATE_FOR_PROSPECTIVE_LEADS,
  READ_PROSPECTIVE_LEADS_COMMUNICATION_FILE_ENTRIES,
  READ_PROSPECTIVE_LEADS_FILE_ENTRIES,
  TEST_EMAIL_OR_SMS_TEMPLATE,
  UPDATE_SMS_TEMPLATE_FOR_PROSPECTIVE_LEADS,
  UPLOAD_PROSPECTIVE_LEADS_COMMUNICATION_FILE,
} from "@roadflex/graphql";
import { ProspectiveLeadsFileEntryType } from "@roadflex/types";

export function useAdminOutreach() {
  const {
    data: data1,
    refetch: refetchOutreachData,
    loading: outreachDataLoading,
  } = useQuery(READ_PROSPECTIVE_LEADS_FILE_ENTRIES, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const outreachData: ProspectiveLeadsFileEntryType[] =
    data1?.readProspectiveLeadsFileEntries?.prospectiveLeadsFileEntries?.map(
      (oldObj: any) => {
        const newObj = {
          ...oldObj,
          createdAt: oldObj.createdAt ? new Date(oldObj.createdAt) : null,
        };
        return newObj;
      },
    ) || [];

  const {
    data: data2,
    refetch: refetchOutreachHistoryData,
    loading: outreachHistoryDataLoading,
  } = useQuery(READ_PROSPECTIVE_LEADS_COMMUNICATION_FILE_ENTRIES, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const outreachHistoryData: ProspectiveLeadsFileEntryType[] =
    data2?.readProspectiveLeadsCommunicationFileEntries?.prospectiveLeadsCommunicationFileEntries?.map(
      (oldObj: any) => {
        const newObj = {
          ...oldObj,
          createdAt: oldObj.createdAt ? new Date(oldObj.createdAt) : null,
        };
        return newObj;
      },
    ) || [];

  const { data: outReachSmsTemplate } = useQuery(
    GET_SMS_TEMPLATE_FOR_PROSPECTIVE_LEADS,
    {
      fetchPolicy: "no-cache",
    },
  );

  const smsTemplate =
    outReachSmsTemplate?.getSmsTemplateForProspectiveLeads || {};

  const [saveSmsTemplateFn, { loading: savingSmsTemplate }] = useMutation(
    UPDATE_SMS_TEMPLATE_FOR_PROSPECTIVE_LEADS,
    {
      refetchQueries: [GET_SMS_TEMPLATE_FOR_PROSPECTIVE_LEADS],
    },
  );

  const [testEmailTemplateFn] = useMutation(TEST_EMAIL_OR_SMS_TEMPLATE);

  const [initiateOutreachAction, { loading: outreachActionLoading }] =
    useMutation(UPLOAD_PROSPECTIVE_LEADS_COMMUNICATION_FILE, {
      refetchQueries: [READ_PROSPECTIVE_LEADS_COMMUNICATION_FILE_ENTRIES],
    });

  return {
    outreachData,
    outreachDataLoading,
    refetchOutreachData,
    outreachHistoryData,
    outreachHistoryDataLoading,
    refetchOutreachHistoryData,
    saveSmsTemplateFn,
    savingSmsTemplate,
    testEmailTemplateFn,
    smsTemplate,
    initiateOutreachAction,
    outreachActionLoading,
  };
}
