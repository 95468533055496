import { useExecuteAdminPresetMutation } from "@roadflex/react-hooks";
import { AdminDataAnalysisType } from "@roadflex/types";
import { customerDashboardExportsMapper } from "@roadflex/web-lib";
import { Button as PrimeReactButton } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable, DataTableSortOrderType } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useEffect, useRef } from "react";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../../buttons";
import { newPaginationTemplate } from "../../datatable/pagination-templates/new-pagination-template";

type GenericDownloadModalProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  tableVariables: {
    exportFileName: string;
    header: string;
    sortField: string;
    sortOrder?: DataTableSortOrderType;
  };
  tableColumnVariables: {
    field: string;
    header: string;
    dataType?: string;
    sortField?: string;
  }[];
  queryType: string;
};

export const GenericDownloadModal = ({
  open,
  setOpen,
  tableVariables,
  tableColumnVariables,
  queryType,
}: GenericDownloadModalProps) => {
  const colNumber = tableColumnVariables.length;

  const { loadFn, data, loading } = useExecuteAdminPresetMutation({
    queryType: queryType,
  });

  const dt = useRef(null);

  const exportCSV = (selectionOnly: boolean) => {
    if (dt.current) {
      (dt.current as DataTable).exportCSV({ selectionOnly });
    }
  };
  const cancelButtonRef = useRef(null);
  const renderFooter = () => {
    return (
      <div className="flex flex-row justify-end">
        <PrimeReactButton
          type="button"
          icon="pi pi-download"
          label="Download CSV"
          className="p-button-outlined p-button-sm p-button-secondary"
          onClick={() => exportCSV(false)}
          style={{ fontFamily: "Inter" }}
        />
        <Button
          type={ButtonType.Button}
          variant={ButtonVariant.SecondaryOutline}
          size={ButtonSize.AppSize}
          className="ml-3"
          onClick={() => setOpen(false)}
          ref={cancelButtonRef}
        >
          Close
        </Button>
      </div>
    );
  };

  useEffect(() => {
    if (open) {
      loadFn();
    }
  }, [open]);

  const userData: AdminDataAnalysisType[] =
    data?.executeAdminPresetQuery?.queryResults || [];

  const userDataToBeExported = userData?.map(function (user) {
    return customerDashboardExportsMapper(user);
  });

  return (
    <Dialog
      header={tableVariables.header}
      visible={open}
      style={{ minWidth: "50vw", maxWidth: "80vw", fontFamily: "Inter" }}
      closeOnEscape={true}
      closable={false}
      onHide={() => setOpen(false)}
      footer={renderFooter}
    >
      <div className="">
        <DataTable
          size="small"
          exportFilename={`${
            tableVariables.exportFileName
          }_${new Date().toLocaleDateString("en-US")}`}
          ref={dt}
          stripedRows
          value={userDataToBeExported}
          paginator
          rows={10}
          dataKey="id"
          sortField={tableVariables.sortField}
          sortOrder={tableVariables.sortOrder ? tableVariables.sortOrder : -1}
          paginatorTemplate={newPaginationTemplate}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          className="!bg-white w-auto min-w-full custom-table !text-brown-500 !border-brown-500"
          rowsPerPageOptions={[10, 20, 50]}
          responsiveLayout="scroll"
          emptyMessage="No available records"
          loading={loading}
          style={{ fontFamily: "Inter" }}
        >
          {colNumber >= 1 && (
            <Column
              sortable
              dataType={
                tableColumnVariables[0].dataType
                  ? tableColumnVariables[0].dataType
                  : undefined
              }
              field={tableColumnVariables[0].field}
              header={tableColumnVariables[0].header}
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              showFilterMenuOptions={false}
              filterMenuClassName="custom-filter"
            />
          )}
          {colNumber >= 2 && (
            <Column
              sortable
              dataType={
                tableColumnVariables[1].dataType
                  ? tableColumnVariables[1].dataType
                  : undefined
              }
              field={tableColumnVariables[1].field}
              header={tableColumnVariables[1].header}
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              showFilterMenuOptions={false}
              showFilterMatchModes={false}
              filterMenuClassName="custom-filter"
            />
          )}
          {colNumber >= 3 && (
            <Column
              sortable
              dataType={
                tableColumnVariables[2].dataType
                  ? tableColumnVariables[2].dataType
                  : undefined
              }
              field={tableColumnVariables[2].field}
              header={tableColumnVariables[2].header}
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
            />
          )}
          {colNumber >= 4 && (
            <Column
              sortable
              dataType={
                tableColumnVariables[3].dataType
                  ? tableColumnVariables[3].dataType
                  : undefined
              }
              field={tableColumnVariables[3].field}
              header={tableColumnVariables[3].header}
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
            />
          )}
          {colNumber >= 5 && (
            <Column
              sortable
              dataType={
                tableColumnVariables[4].dataType
                  ? tableColumnVariables[4].dataType
                  : undefined
              }
              field={tableColumnVariables[4].field}
              header={tableColumnVariables[4].header}
              sortField={
                tableColumnVariables[4].sortField
                  ? tableColumnVariables[4].sortField
                  : undefined
              }
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
            />
          )}
          {colNumber >= 6 && (
            <Column
              sortable
              dataType={
                tableColumnVariables[5].dataType
                  ? tableColumnVariables[5].dataType
                  : undefined
              }
              field={tableColumnVariables[5].field}
              header={tableColumnVariables[5].header}
              sortField={
                tableColumnVariables[5].sortField
                  ? tableColumnVariables[5].sortField
                  : undefined
              }
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
            />
          )}
        </DataTable>
      </div>
    </Dialog>
  );
};
