/** @format */

import { ENUM_ADMIN_ROLES } from "@prisma/client";
import { ADMIN_PERMISSIONS } from "@roadflex/constants";
import { flatMap, uniq } from "lodash";
import React from "react";
import Redirect from "../redirect/redirect";

interface PermissionGuardProps {
  scopes: string[];
  roles: ENUM_ADMIN_ROLES[];
  isPage?: boolean;
  children: React.ReactNode;
}
const hasPermission = ({
  permissions,
  scopes,
}: {
  permissions: string[];
  scopes: string[];
}) => {
  const scopesMap: { [key: string]: boolean } = {};
  scopes.forEach((scope) => {
    scopesMap[scope] = true;
  });

  return permissions.some((permission) => scopesMap[permission]);
};

export const PermissionGuard = (props: PermissionGuardProps) => {
  const { roles, scopes, isPage = false, children } = props;
  const permissions = uniq(flatMap(roles, (role) => ADMIN_PERMISSIONS[role]));

  const permissionGranted = hasPermission({ permissions, scopes });

  if (!permissionGranted && isPage) return <Redirect path="/"></Redirect>;
  if (!permissionGranted && !isPage) return null;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <React.Fragment>{children || null}</React.Fragment>;
};
