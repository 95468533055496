import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import getConfig from "next/config";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment } from "react";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;

interface NavigationOverlayProps {
  open: boolean;
  toggle: () => void;
  navigation: any;
  page: any;
  setPage: any;
}

export default function NavigationOverlay({
  open,
  toggle,
  navigation,
  page,
  setPage,
}: NavigationOverlayProps) {
  const router = useRouter();
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-40 flex md:hidden"
        onClose={toggle}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-75" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex flex-col flex-1 w-full max-w-xs bg-white">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 pt-2 -mr-8">
                <button
                  type="button"
                  className="flex items-center justify-center w-6 h-6 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={toggle}
                >
                  <XMarkIcon
                    className="w-6 h-6 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </Transition.Child>
            <div
              className={`h-full md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 bg-gradient-to-br from-brown-850 to-brown-750`}
            >
              <div className="flex flex-col flex-grow h-full overflow-y-auto">
                <div className="relative h-16 m-2">
                  <Image
                    className=""
                    src={`${NEXT_PUBLIC_CDN_URL}/svg/roadflex-full-logo.svg`}
                    alt="Roadflex logo"
                    layout="fill"
                  />
                </div>
                <nav
                  className="flex flex-col flex-1 mt-5 overflow-y-auto divide-y divide-white"
                  aria-label="Sidebar"
                >
                  <div className="space-y-1">
                    {navigation.map((navBlock: any, index: number) => (
                      <Fragment key={index}>
                        {navBlock.map((item: any) => (
                          <Link
                            href={item.href}
                            key={item.name}
                            prefetch={false}
                          >
                            <a
                              key={item.name}
                              onClick={(e) => {
                                setPage(item.current);
                                toggle();
                              }}
                              className={classNames(
                                item.href === router?.asPath
                                  ? "font-semibold"
                                  : "hover:animate-pulse",
                                `text-white group flex items-center px-3 py-2 text-xs leading-6`,
                              )}
                              aria-current={
                                page === item.current ? "page" : undefined
                              }
                            >
                              <item.icon
                                className="flex-shrink-0 w-6 h-6 mr-4 text-white"
                                aria-hidden="true"
                              />
                              {item.name}
                            </a>
                          </Link>
                        ))}
                        {navBlock.length > 0 && (
                          <div className="w-full h-[1px] bg-orange-200 last:hidden"></div>
                        )}
                      </Fragment>
                    ))}
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}
