/** @format */

import { useQuery } from "@apollo/client";
import { READ_DEFAULT_SETTINGS } from "@roadflex/graphql";

export const useDefaultSettings = () => {
  const {
    data: defaultSettingsData,
    refetch: defaultSettingsRefetch,
    loading: defaultSettingsLoading,
    ...rest
  } = useQuery(READ_DEFAULT_SETTINGS, {
    fetchPolicy: "no-cache",
  });

  return {
    defaultSettingsData,
    defaultSettingsRefetch,
    defaultSettingsLoading,
    ...rest,
  };
};
