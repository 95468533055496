/** @format */
import { useMutation } from "@apollo/client";
import { ADMIN_DOWNLOAD_LOGS } from "@roadflex/graphql";
import { ActivityLogMapperType } from "@roadflex/types";
import { convertToUTC, humanize } from "@roadflex/utils";
import { activityLogMapper } from "@roadflex/web-lib";
import { Calendar, CalendarChangeParams } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useRef, useState } from "react";
import { nameFilterTemplate } from "../../datatable/datatable";
import { newPaginationTemplate } from "../../datatable/pagination-templates/new-pagination-template";
import { Toast } from "../../toast-message/toast";

export type LogHistoryModalProps = {
  openLogHistoryModal: boolean;
  setOpenLogHistoryModal: (value: boolean) => void;
  logType: string;
  activityTemplate: (rowData: ActivityLogMapperType) => JSX.Element;
  adminDateTimeTemplate: (date: Date) => JSX.Element;
};

export const LogHistoryModal = ({
  openLogHistoryModal,
  setOpenLogHistoryModal,
  logType,
  activityTemplate,
  adminDateTimeTemplate,
}: LogHistoryModalProps) => {
  const exportFileName = humanize(logType.toLowerCase());
  const [downloadLogsDate, setDownloadLogsDate] = useState<
    Date | Date[] | undefined
  >(undefined);
  const [submittingRequest, setsubmittingRequest] = useState(false);
  const [logsData, setLogsData] = useState<ActivityLogMapperType[]>([]);
  const [AdminDownloadLogs] = useMutation(ADMIN_DOWNLOAD_LOGS);
  const columns = [];

  const handleDownloadLogs = (date: Date | Date[] | undefined) => {
    if (!Array.isArray(date) && date) {
      setsubmittingRequest(true);
      AdminDownloadLogs({
        variables: {
          data: { logType, downloadLogsDate: convertToUTC(date) },
        },
      })
        .then(({ data }) => {
          if (data?.adminDownloadActivityLogs?.code === "200") {
            let mappedLogs: ActivityLogMapperType[] = [];
            if (logType === "ADMIN") {
              mappedLogs = data?.adminDownloadActivityLogs?.adminLogs || [];
            } else if (logType === "USER") {
              mappedLogs = data?.adminDownloadActivityLogs?.userLogs || [];
            }
            mappedLogs = mappedLogs.map((log) => {
              return activityLogMapper(log);
            });
            setLogsData(mappedLogs);
          } else {
            setLogsData([]);
            Toast({
              type: "error",
              message: data?.adminDownloadActivityLogs?.message,
            });
          }
          setsubmittingRequest(false);
        })
        .catch((err) => {
          setLogsData([]);
          // console.log("err =>", err);
          Toast({
            type: "error",
            message: err.message,
          });
          setsubmittingRequest(false);
        });
    }
  };

  const dt = useRef(null);

  if (logType === "ADMIN") {
    columns.push(
      <Column
        filter
        sortable
        filterPlaceholder="Search by name"
        field="fullName"
        header={`Admin Name`}
        className="text-sm"
        showFilterMenuOptions={false}
        showFilterMatchModes={false}
        filterMenuStyle={{
          width: "14rem",
        }}
        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
        headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
        filterMenuClassName="custom-filter"
        filterElement={(e) => nameFilterTemplate(e)}
      />,
      <Column
        filter
        sortable
        filterPlaceholder="Search by activity"
        field="activity"
        header="Activity"
        className="text-sm"
        body={activityTemplate}
        showFilterMenuOptions={false}
        showFilterMatchModes={false}
        filterMenuStyle={{
          width: "14rem",
        }}
        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
        headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
        filterMenuClassName="custom-filter"
        filterElement={(e) => nameFilterTemplate(e)}
      />,
      <Column
        filter
        sortable
        filterPlaceholder="Search by type"
        field="activityTypeString"
        header="Type"
        className="text-sm"
        showFilterMenuOptions={false}
        showFilterMatchModes={false}
        filterMenuStyle={{
          width: "14rem",
        }}
        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base capitalize"
        headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
        filterMenuClassName="custom-filter"
        filterElement={(e) => nameFilterTemplate(e)}
      />,
      <Column
        sortable
        dataType="date"
        filterField="createdAt"
        field="createdAt"
        header="Time (Your timezone)"
        className="text-sm"
        body={(r: ActivityLogMapperType) =>
          r.createdAt ? adminDateTimeTemplate(r.createdAt) : ""
        }
        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
        headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
      />,
    );
  } else {
    columns.push(
      <Column
        filter
        sortable
        filterPlaceholder="Search by Account email"
        field="parentEmail"
        header="Account email"
        showFilterMenuOptions={false}
        showFilterMatchModes={false}
        filterMenuStyle={{
          width: "14rem",
        }}
        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
        headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
        filterMenuClassName="custom-filter"
        filterElement={(e) => nameFilterTemplate(e)}
      />,
      <Column
        filter
        sortable
        filterPlaceholder="Search by Account name"
        field="fullName"
        header="Account name"
        className="text-sm"
        showFilterMenuOptions={false}
        showFilterMatchModes={false}
        filterMenuStyle={{
          width: "14rem",
        }}
        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
        headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
        filterMenuClassName="custom-filter"
        filterElement={(e) => nameFilterTemplate(e)}
      />,
      <Column
        filter
        sortable
        filterPlaceholder="Search by Action email"
        field="email"
        header="Action taken by"
        className="text-sm"
        showFilterMenuOptions={false}
        showFilterMatchModes={false}
        filterMenuStyle={{
          width: "14rem",
        }}
        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
        headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
        filterMenuClassName="custom-filter"
        filterElement={(e) => nameFilterTemplate(e)}
      />,
      <Column
        filter
        sortable
        filterPlaceholder="Search by activity"
        field="activity"
        header="Activity"
        className="text-sm"
        body={activityTemplate}
        showFilterMenuOptions={false}
        showFilterMatchModes={false}
        filterMenuStyle={{
          width: "14rem",
        }}
        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
        headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
        filterMenuClassName="custom-filter"
        filterElement={(e) => nameFilterTemplate(e)}
      />,
      <Column
        filter
        sortable
        filterPlaceholder="Search by type"
        field="activityTypeString"
        header="Type"
        className="text-sm"
        showFilterMenuOptions={false}
        showFilterMatchModes={false}
        filterMenuStyle={{
          width: "14rem",
        }}
        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base capitalize"
        headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
        filterMenuClassName="custom-filter"
        filterElement={(e) => nameFilterTemplate(e)}
      />,
      <Column
        sortable
        dataType="date"
        filterField="createdAt"
        field="createdAt"
        header="Time (Your timezone)"
        className="text-sm"
        body={(r: ActivityLogMapperType) =>
          r.createdAt ? adminDateTimeTemplate(r.createdAt) : ""
        }
        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base capitalize"
        headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
      />,
    );
  }

  return (
    <Dialog
      header={
        <div className="flex flex-col flex-wrap justify-start w-full col-span-1 gap-20 sm:w-auto sm:flex-row">
          <div className="flex items-center">{`View archived ${exportFileName} logs`}</div>
          <div className="flex items-center justify-start col-span-1 gap-8 text-base ">
            <div className="flex items-center justify-start col-span-1 gap-2">
              <label htmlFor="monthpicker">Select Year & Month</label>
              <Calendar
                id="monthpicker"
                className="custom-calendar"
                panelClassName="custom-calendar-panel"
                value={downloadLogsDate}
                onChange={(e: CalendarChangeParams) => {
                  setDownloadLogsDate(e.value as Date | Date[]);
                  handleDownloadLogs(e.value as Date | Date[]);
                }}
                view="month"
                dateFormat="mm/yy"
              />
            </div>
          </div>
        </div>
      }
      visible={openLogHistoryModal}
      style={{ width: "80vw", height: "80vh", fontFamily: "Inter" }}
      footer={<div></div>}
      onHide={() => setOpenLogHistoryModal(false)}
    >
      <div className="border-b border-gray-200 shadow sm:rounded-md">
        <DataTable
          size="small"
          ref={dt}
          stripedRows
          value={logsData}
          paginator
          rows={10}
          dataKey="id"
          sortField="createdAt"
          sortOrder={-1}
          paginatorTemplate={newPaginationTemplate}
          rowsPerPageOptions={[10, 20, 50]}
          responsiveLayout="scroll"
          emptyMessage="No available records"
          loading={submittingRequest}
          style={{ fontFamily: "Inter" }}
          className="!bg-white custom-table !text-brown-500 !border-brown-500"
        >
          {columns}
        </DataTable>
      </div>
    </Dialog>
  );
};
