/** @format */
import { useLazyQuery } from "@apollo/client";
import { GET_ACH_PAYMENT_FAILED_DATA } from "@roadflex/graphql";

interface useAchPaymentFailedProps {
  paymentHistoryStartDate: string;
  paymentHistoryEndDate: string;
  paymentStatus: string;
  isAutomatedPayment: boolean;
}

export const useAchPaymentFailed = ({
  paymentHistoryStartDate,
  paymentHistoryEndDate,
  paymentStatus,
  isAutomatedPayment,
}: useAchPaymentFailedProps) => {
  const [
    loadAchPaymentFailed,
    { data: achPaymentFailedData, loading: achPaymentFailedLoading },
  ] = useLazyQuery(GET_ACH_PAYMENT_FAILED_DATA, {
    fetchPolicy: "no-cache",
    variables: {
      data: {
        paymentHistoryStartDate,
        paymentHistoryEndDate,
        paymentStatus,
        isAutomatedPayment,
      },
    },
  });
  return {
    loadAchPaymentFailed,
    achPaymentFailedData,
    achPaymentFailedLoading,
  };
};
